export default `
.page-content-width {
  padding: 0 0 0 8px !important;
}

.listToolbar, div[data-test-id="table-footer-content"] {
  padding-left: 20px !important;
}

th.col0 {
  padding-left: 12px !important;
}

td.col0 {
  padding-left: 16px !important;
}

.table-list-footer-td {
  padding-left: 20px !important;
}
`
