import {
  BrowserClient,
  createBoundLocalization,
  HtmlPostProcessor,
} from '@wf-mfe/localize-react'

const redrockClient = new BrowserClient({
  namespace: 'redrock',
  enableTerminology: true,
})
const {
  LoadLocalizations,
  Localization,
  useLocalization,
} = createBoundLocalization(redrockClient)
export { LoadLocalizations, Localization, useLocalization }

const htmlPostProcessor = new HtmlPostProcessor()
export function sanitizeHtml(s) {
  return htmlPostProcessor.run(s)
}
