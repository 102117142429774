import React, { useState, useEffect } from 'react'

import { KaminoShimWrapper } from './KaminoShimWrapper.js'
import { _replayState$, _internalState$ } from './internalState.js'

export function KaminoShimCacheSystem({ basePathname }) {
  const [shimsPropsForBasePath, setShimsPropsForBasePath] = useState([])

  useEffect(() => {
    const subscription = _replayState$.subscribe((cache) => {
      setShimsPropsForBasePath(
        Array.from(cache.get(basePathname)?.entries() ?? [])
      )
    })

    return () => {
      _internalState$.next({ type: 'DELETE_CACHE', payload: { basePathname } })
      subscription.unsubscribe()
    }
  }, [basePathname])

  return (
    <>
      {shimsPropsForBasePath.map(
        ([key, { parameters, isVisible, ...shimProps }]) => (
          <KaminoShimWrapper
            key={key}
            shimProps={shimProps}
            parameters={parameters}
            isVisible={isVisible}
            frameID={isVisible ? 'main-frame' : ''}
          />
        )
      )}
    </>
  )
}
