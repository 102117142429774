export const getMonolithBaseStyles = () => `
  html body {
    overflow: hidden !important;
  }

  .TabPanel > nav {
    display: none;
  }

  .page-content-width {
    padding: 0 !important;
  }

  #layout-content {
    max-width: none;
    min-height: calc(100vh - 2px);
  }

  #layout-main {
    padding-bottom: 0 !important;
    min-height: calc(100vh - 2px);
  }

  div[data-name="react-datatable"] div[data-wf-reactroot] > div {
    height: calc(100vh - 49px) !important;
  }

  #document-central-base {
    min-height: 100vh !important;
  }

  .hidden {
    display: none;
  }


  .btn.btn-primary {
  align-items: center !important;
  cursor: pointer !important;
  display: inline-flex !important;
  background-color: rgb(13, 102, 208) !important;
  border: 2px solid rgb(13, 102, 208) !important;
  color: rgb(255, 255, 255) !important;
  font-family: 'Adobe Clean', 'Proxima Nova', 'Tahoma', 'Verdana', 'Arial', sans-serif !important;
  height: 32px !important;
  border-radius: 16px !important;
  font-size: 0.875rem !important;
  font-weight: 700 !important;
  justify-content: center !important;
  line-height: 1.3rem !important;
  margin-left: 8px !important;
  min-width: 32px !important;
  padding: 3px 14px 5px !important;
  position: relative !important;
  text-align: center !important;
-webkit-font-smoothing: antialiased !important;
  transition: background 130ms ease-out, border-color 130ms ease-out, color 130ms ease-out !important;
  box-shadow: none; !important;
  }

  .btn.btn-primary:after {
    content: '' !important;
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0px !important;
    border-radius: 18px !important;
    box-shadow: 0px 0px 0px 2px transparent; !important;
    transition: all 130ms ease-out !important;
  }

  .btn.btn-primary:focus-visible:after {
    margin: -4px !important;
    box-shadow: 0px 0px 0px 2px rgb(13, 102, 208); !important;
  }

  

  .btn.btn-primary:hover:enabled, .btn.btn-primary:focus:enabled {
    background-color: rgb(9, 90, 186) !important;
  border: 2px solid rgb(9, 90, 186) !important;
  color: rgb(255, 255, 255) !important;
  }

  .btn.btn-primary:disabled {
    cursor: initial !important;
    background-color: rgb(234, 234, 234) !important;
  border: 2px solid rgb(234, 234, 234) !important;
  color: rgb(179, 179, 179) !important;
  }

  

  .btn.btn-primary + .btn.btn-text{
      margin-left: -8px !important;
    }
  

  

	
  .btn.btn-secondary {
  align-items: center !important;
  cursor: pointer !important;
  display: inline-flex !important;
  background-color: transparent !important;
  border: 2px solid rgb(202, 202, 202) !important;
  color: rgb(75, 75, 75) !important;
  font-family: 'Adobe Clean', 'Proxima Nova', 'Tahoma', 'Verdana', 'Arial', sans-serif !important;
  height: 32px !important;
  border-radius: 16px !important;
  font-size: 0.875rem !important;
  font-weight: 700 !important;
  justify-content: center !important;
  line-height: 1.3rem !important;
  margin-left: 8px !important;
  min-width: 32px !important;
  padding: 3px 14px 5px !important;
  position: relative !important;
  text-align: center !important;
-webkit-font-smoothing: antialiased !important;
  transition: background 130ms ease-out, border-color 130ms ease-out, color 130ms ease-out !important;
  box-shadow: none; !important;
  }

  .btn.btn-secondary:after {
    content: '' !important;
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0px !important;
    border-radius: 18px !important;
    box-shadow: 0px 0px 0px 2px transparent; !important;
    transition: all 130ms ease-out !important;
  }

  .btn.btn-secondary:focus-visible:after {
    margin: -4px !important;
    box-shadow: 0px 0px 0px 2px rgb(13, 102, 208); !important;
  }

  

  .btn.btn-secondary:hover:enabled, .btn.btn-secondary:focus:enabled {
    background-color: rgb(225, 225, 225) !important;
  border: 2px solid rgb(179, 179, 179) !important;
  color: rgb(44, 44, 44) !important;
  }

  .btn.btn-secondary:disabled {
    cursor: initial !important;
    background-color: rgb(234, 234, 234) !important;
  border: 2px solid rgb(234, 234, 234) !important;
  color: rgb(179, 179, 179) !important;
  }

  

  

  

	
  .btn.btn-text {
  align-items: center !important;
  cursor: pointer !important;
  display: inline-flex !important;
  background-color: transparent !important;
  border: 2px solid transparent !important;
  color: rgb(75, 75, 75) !important;
  font-family: 'Adobe Clean', 'Proxima Nova', 'Tahoma', 'Verdana', 'Arial', sans-serif !important;
  height: 32px !important;
  border-radius: 16px !important;
  font-size: 0.875rem !important;
  font-weight: 700 !important;
  justify-content: center !important;
  line-height: 1.3rem !important;
  margin-left: 8px !important;
  min-width: 32px !important;
  padding: 3px 14px 5px !important;
  position: relative !important;
  text-align: center !important;
-webkit-font-smoothing: antialiased !important;
  transition: background 130ms ease-out, border-color 130ms ease-out, color 130ms ease-out !important;
  box-shadow: none; !important;
  }

  .btn.btn-text:after {
    content: '' !important;
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    margin: 0px !important;
    border-radius: 18px !important;
    box-shadow: 0px 0px 0px 2px transparent; !important;
    transition: all 130ms ease-out !important;
  }

  .btn.btn-text:focus-visible:after {
    margin: -4px !important;
    box-shadow: 0px 0px 0px 2px rgb(13, 102, 208); !important;
  }

  

  .btn.btn-text:hover:enabled, .btn.btn-text:focus:enabled {
    background-color: rgb(225, 225, 225) !important;
  border: 2px solid rgb(225, 225, 225) !important;
  color: rgb(44, 44, 44) !important;
  }

  .btn.btn-text:disabled {
    cursor: initial !important;
    background-color: transparent !important;
  border: 2px solid transparent !important;
  color: rgb(179, 179, 179) !important;
  }

  .btn.btn-text:hover .button-label {
      border-bottom: none !important;
  }



  form.editing .edit-link.btn {
    display: none !important;
  }

h1:not(.ignoreInject) {
      
  color: rgb(44, 44, 44) !important;
  font-size: 2rem !important;
  font-weight: 600 !important;
  line-height: 2.5 !important;
  margin: 0 !important;
  padding: 2px 0 2px 0 !important;

    }h2 {
      
  color: rgb(44, 44, 44) !important;
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  line-height: 2 !important;
  margin: 0 !important;
  padding: 1px 0 3px 0 !important;

    }h3 {
      
  color: rgb(44, 44, 44) !important;
  font-size: 1.25rem !important;
  font-weight: 800 !important;
  line-height: 1.5 !important;
  margin: 0 !important;
  padding: 2px 0 2px 0 !important;

    }h4 {
      
  color: rgb(75, 75, 75) !important;
  font-size: 1rem !important;
  font-weight: 700 !important;
  line-height: 1.25 !important;
  margin: 0 !important;
  padding: 1px 0 3px 0 !important;

    }h5 {
      
  color: rgb(44, 44, 44) !important;
  font-size: 0.75rem !important;
  font-weight: 600 !important;
  line-height: 1 !important;
  margin: 0 !important;
  padding: 0 !important;

    }a {
      
  color: rgb(20, 115, 230) !important;
  font-size: 0.875rem !important;
  font-weight: 600 !important;
  line-height: 1.25 !important;
  padding: 2px 0 2px 0 !important;
  margin: 0 !important;
  cursor: pointer !important;
  text-decoration: none !important;

  undefined
  
    }.passiveLink {
      
  color:  rgb(44, 44, 44) !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  line-height: 1.25 !important;
  cursor: pointer !important;
  text-decoration: none !important;

  undefined
  
    }.fnt-lg {
      
  color: rgb(75, 75, 75) !important;
  font-size: 1rem !important;
  font-weight: 700 !important;
  line-height: 1.25 !important;
  margin: 0 !important;
  padding: 2px 0 2px !important;
  
    }.fnt-mdm {
      
  color: rgb(44, 44, 44) !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  line-height: 1.25 !important;
  margin: 0 !important;
  padding: 2px 0 2px !important;

    }.fnt-sm {
      
  color: rgb(75, 75, 75) !important;
  font-size: 0.75rem !important;
  font-weight: 400 !important;
  line-height: 1 !important;
  margin: 0 !important;
  padding: 0 !important;

    }.fnt-xsm {
      
  color: rgb(110, 110, 110) !important;
  font-size: 0.625rem !important;
  font-weight: 700 !important;
  line-height: .75 !important;
  margin: 0 !important;
  padding: 3px 0 1px !important;

    }
`
