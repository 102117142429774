let lastPositiveToast = null

export const ToastQueue = {
  async neutral() {
    const { neutral } = await lazyLoadToastQueue()
    neutral.apply(null, arguments)
  },
  async positive() {
    const { positive } = await lazyLoadToastQueue()
    lastPositiveToast?.()
    if (arguments?.[1]?.onAction) {
      lastPositiveToast = positive.apply(null, arguments)
    } else {
      positive.apply(null, arguments)
    }
  },
  async negative() {
    const { negative } = await lazyLoadToastQueue()
    negative.apply(null, arguments)
  },
  async info() {
    const { info } = await lazyLoadToastQueue()
    info.apply(null, arguments)
  },
}

async function lazyLoadToastQueue() {
  const { ToastQueue } = await import('@react-spectrum/toast')
  return ToastQueue
}
