const multipleSlashes = /\w+\//
const LAST_WORD_AFTER_SLASH = /\/[^/]+$/

// Most pages in Quicksilver followed a pattern with the Page path prop,
// the function was written for the common case and exceptions were
// later added to handle areas where there was a mismatch.
//
// The Setup area opens these pages as their own full page vs the more
// traditional subpage system we have with "regular" secondary nav
//
// For these pages the basePathname supplied to the ShimRenderer from
// props.match.url is the full path vs the basepath on pages with regular
// secondary nav
//
// The reporting area also has full screen shims for report creation and
// report editing, each case was treated differently
//
// Using a function per each in case we run into other pages later
// which need other pathname alterations than a simple passthrough
let SPECIAL_PAGES = new Map()

SPECIAL_PAGES.set(/setup\/company\/details/, (pathname) => pathname)
SPECIAL_PAGES.set(/setup\/schedule\/(add|edit|copy)/, (pathname) => pathname)
SPECIAL_PAGES.set(
  /group\/[a-f0-9]{32}\/schedule\/(add|edit|copy)/,
  (pathname) => pathname
)
SPECIAL_PAGES.set(/report\/\w+\/new/, (pathname) => pathname)
SPECIAL_PAGES.set(/dashboard\/new/, (pathname) => pathname)
SPECIAL_PAGES.set(/dashboard\/[a-f0-9]{32}$/, (pathname) => pathname)
SPECIAL_PAGES.set(/lists\/(views|groupings|filters)/, (pathname) => pathname)
SPECIAL_PAGES.set(/report\/[a-f0-9]{32}\/edit/, (pathname) =>
  pathname.replace('/edit', '')
)
SPECIAL_PAGES.set(
  /report\/[a-f0-9]{32}\/addToDashboard/,
  (pathname) => pathname
)
SPECIAL_PAGES.set(/calendars\/[a-f0-9]{32}/, (pathname) => pathname)
SPECIAL_PAGES.set(/queuetopicgroup\/[a-f0-9]{32}/, (pathname) => pathname)
SPECIAL_PAGES.set(/routingrule\/[a-f0-9]{32}/, (pathname) => pathname)
SPECIAL_PAGES.set(/group\/[a-f0-9]{32}\/company/, (pathname) => pathname)
SPECIAL_PAGES.set(/document\/request\/[a-f0-9]{32}/, (pathname) => pathname)
SPECIAL_PAGES.set(/team\/[a-f0-9]{32}\/iteration\/current/, (pathname) =>
  pathname.replace('/iteration/current', '')
)
SPECIAL_PAGES.set(/login\/convertUsername/, (pathname) => pathname)

export const getBasePathnameFromPathname = (pathname) => {
  for (let [specialPathnameRegex, pathnameFunction] of SPECIAL_PAGES) {
    if (specialPathnameRegex.test(pathname)) {
      return pathnameFunction(pathname)
    }
  }

  return multipleSlashes.test(pathname)
    ? pathname.replace(LAST_WORD_AFTER_SLASH, '')
    : pathname
}
