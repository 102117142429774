import { Component } from 'react'
import { isEqual } from 'lodash'

import { _internalState$ } from './internalState.js'

import { getBasePathnameFromPathname } from './utilities.js'

function shimActivation(props, isVisible) {
  const basePathname =
    props?.basePathname ??
    getBasePathnameFromPathname(props?.match?.url ?? window.location.pathname)

  _internalState$.next({
    type: 'VISIBILITY_CHANGE',
    payload: { basePathname, props, isVisible },
  })
}

export class ShimRenderer extends Component {
  componentDidMount() {
    shimActivation(this.props, true)
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps, this.props)) {
      return shimActivation(this.props, true)
    }
  }

  componentWillUnmount() {
    shimActivation(this.props, false)
  }

  render() {
    return null
  }
}
