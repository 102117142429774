import { ReplaySubject } from 'rxjs'
import { shareReplay, scan } from 'rxjs/operators'

const CACHED_SHIM_COUNT_PER_PAGE = 3

export const _internalState$ = new ReplaySubject(1)
export const _replayState$ = _internalState$
  .pipe(scan(stateReducer, new Map()), shareReplay(1))
  .asObservable()

// exported for testing
export function stateReducer(cache, { type, payload }) {
  if (type === 'VISIBILITY_CHANGE') {
    const { props, basePathname, isVisible } = payload
    // create cache for basePathname
    if (!cache.has(basePathname)) {
      if (isVisible) {
        cache.set(basePathname, new Map())
      } else {
        return cache
      }
    }

    const { parameters } = props
    const newShimParamKey = JSON.stringify(parameters)
    const cacheForBaseUrl = cache.get(basePathname)

    for (let [shimParamKey, shimProps] of cacheForBaseUrl.entries()) {
      if (shimProps.isVisible) {
        cacheForBaseUrl.set(shimParamKey, { ...shimProps, isVisible: false })
      }
    }

    cacheForBaseUrl.set(newShimParamKey, { ...props, isVisible })

    if (cacheForBaseUrl.size > CACHED_SHIM_COUNT_PER_PAGE) {
      let newEntries = Array.from(cacheForBaseUrl.entries())
      newEntries.shift()
      cache.set(basePathname, new Map(newEntries))
    }
  } else if (type === 'DELETE_CACHE') {
    cache.delete(payload.basePathname)
  }

  return cache
}
