import warning from 'warning'

export const postMessage = (message, frameIdentifier) => {
  warning(
    message.type,
    'type is a required property of messages for the KaminoShim'
  )

  const iframe =
    frameIdentifier?.tagName === 'IFRAME'
      ? frameIdentifier
      : document.querySelector(
          frameIdentifier ? `iframe#${frameIdentifier}` : 'iframe#main-frame'
        )
  warning(iframe, 'KaminoShim iframe not found on page, message not delivered')

  if (iframe) {
    iframe.contentWindow.postMessage(
      { source: 'quicksilver', message },
      window.location.origin
    )
  }
}
