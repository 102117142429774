import React from 'react'
import { useLocation, useNavigate, Route, Routes } from 'react-router-dom'
import { isEqual } from 'lodash'
import { css } from 'react-emotion'

import { KaminoShim } from './KaminoShim'
import { postMessage as postMessageToShim } from './postMessage'
import { customPerformanceMetrics } from '@wf-mfe/logger'

const { logCustomPageTiming } = customPerformanceMetrics

const firefoxQuirk = (value) => css`
  /**
		* Firefox treats "flex" layout as "unbreakable" when printing.
		* As a result, Firefox will just run them off the bottom of
		* the page when they are too long.
		*/
  display: ${value};
`

const mediaPrint = (ffQuirk = 'initial') => css`
  @media print {
    overflow: visible;
    ${firefoxQuirk(ffQuirk)}
  }
`

export class KaminoShimWrapper extends React.Component {
  constructor(props) {
    super(props)

    this.kaminoShimContainerRef = React.createRef()
  }

  shouldComponentUpdate(nextProps) {
    const haveParametersChanged = !isEqual(
      this.props.parameters,
      nextProps.parameters
    )
    const hasVisibilityChanged = this.props.isVisible !== nextProps.isVisible
    const hasFrameIDChanged = this.props.frameID !== nextProps.frameID

    /*
      A very ugly workaround. But if we let the re-render happen,
      the Kamino Shim is going to be reloaded.
      We might need to rewrite this later.
    */

    if (hasVisibilityChanged) {
      if (nextProps.isVisible) {
        // TODO if we can't make a count derived from fromCache
        // we might need to fire another custom event here
        logCustomPageTiming({
          time: performance.now(),
          isShim: true,
          fromCache: true,
        })
      }
    }

    if (hasFrameIDChanged) {
      const iframe = this.kaminoShimContainerRef.current.querySelector('iframe')
      iframe.id = nextProps.frameID
      if (nextProps.frameID) {
        if (nextProps.shimProps.refreshOnActivating) {
          postMessageToShim({
            type: 'reloadTile',
          })
        }
      }
    }

    if (!isEqual(this.props.shimProps.obj, nextProps.shimProps.obj)) {
      return true
    }

    return haveParametersChanged || hasVisibilityChanged
  }

  render() {
    const { parameters, shimProps, frameID, isVisible } = this.props
    return (
      <div
        ref={this.kaminoShimContainerRef}
        className={css`
          display: ${isVisible ? 'flex' : 'none'};
          flex-grow: 1;
          max-width: 100%;
          height: 100%;
          overflow: auto;
          position: relative;
          z-index: 0;
          @media all and (-ms-high-contrast: none),
            (-ms-high-contrast: active) {
            iframe {
              height: 100%;
            }
          }
          ${mediaPrint(isVisible ? 'initial' : 'none')};
        `}
      >
        <Routes>
          <Route
            path="/*"
            element={
              <RoutePropsProvider
                shimProps={shimProps}
                parameters={parameters}
                frameID={frameID}
              />
            }
          />
        </Routes>
      </div>
    )
  }
}

function RoutePropsProvider({ shimProps, parameters, frameID }) {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <KaminoShim
      {...shimProps}
      navigate={navigate}
      location={location}
      parameters={parameters}
      frameID={frameID}
    />
  )
}
