import { createReactLifecycles } from 'single-spa-workfront'
import { KaminoShimContainer } from './KaminoShimContainer.js'

const kaminoContainerDivId = 'wf-mfe__kamino'

const lifecycles = createReactLifecycles({
  suppressComponentDidCatchWarning: true,
  rootComponent: KaminoShimContainer,
  domElementGetter: () => {
    let kaminoContainerDiv = document.querySelector(`#${kaminoContainerDivId}`)
    if (kaminoContainerDiv == null) {
      kaminoContainerDiv = document.createElement('div')
      kaminoContainerDiv.id = kaminoContainerDivId
      document
        .querySelector('#active-applications')
        .appendChild(kaminoContainerDiv)
    }
    return kaminoContainerDiv
  },
})
export const bootstrap = lifecycles.bootstrap
export const mount = lifecycles.mount
export const unmount = lifecycles.unmount

export const devtools = {
  overlays: {
    includeWFDefaultSelector: false,
  },
}

export { ShimRenderer } from './ShimRenderer.js'
export { KaminoShim } from './KaminoShim'
export { postMessage } from './postMessage'

export default KaminoShimContainer
