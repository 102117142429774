import React, { Suspense } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'

import { PageContentSlot } from '@wf-mfe/layout'
import { MFELoggerAndReactErrorBoundary } from '@wf-mfe/logger'

import { KaminoShimCacheSystem } from './KaminoShimCacheSystem.js'
import { getBasePathnameFromPathname } from './utilities.js'

const MFE_NAME = '@wf-mfe/kamino'

export const KaminoShimContainer = () => {
  return (
    <PageContentSlot mfeName={MFE_NAME}>
      <Suspense fallback="">
        <Routes>
          <Route path="/*" element={<KacheSystemReRenderOnBasePathChange />} />
        </Routes>
      </Suspense>
    </PageContentSlot>
  )
}

const { ErrorBoundary } = MFELoggerAndReactErrorBoundary({ name: MFE_NAME })

function KacheSystemReRenderOnBasePathChange() {
  const { pathname } = useLocation()
  const basePathname = getBasePathnameFromPathname(pathname)
  return (
    <ErrorBoundary>
      <KaminoShimCacheSystem key={basePathname} basePathname={basePathname} />
    </ErrorBoundary>
  )
}
